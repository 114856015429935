// Dependency for getSelector function from web-vitals package below
const getName = (node: Node) => {
  const name = node.nodeName;
  return node.nodeType === 1 ? name.toLowerCase() : name.toUpperCase().replace(/^#/, '');
};

// Modified version of getSelector copied from web-vitals package to incorporate data-testid attributes if present
// https://github.com/GoogleChrome/web-vitals/blob/cc47f80102907734d145eb92688950168d918321/src/lib/getSelector.ts
export const getSelector = (node: Node | null | undefined, maxLen?: number) => {
  let sel = '';

  try {
    while (node && node.nodeType !== 9) {
      const el: Element = node as Element;
      let part;
      if (el.id) {
        part = `#${el.id}`;
      } else if (el.getAttribute('data-testid')) {
        part = `[data-testid="${el.getAttribute('data-testid')}"]`;
      } else {
        part =
          getName(el) +
          (el.classList &&
          el.classList.value &&
          el.classList.value.trim() &&
          el.classList.value.trim().length
            ? `.${el.classList.value.trim().replace(/\s+/g, '.')}`
            : '');
      }
      if (sel.length + part.length > (maxLen || 100) - 1) return sel || part;
      sel = sel ? `${part}>${sel}` : part;
      if (el.id) break;
      // eslint-disable-next-line no-param-reassign
      node = el.parentNode;
    }
  } catch (err) {
    // Do nothing...
  }
  return sel;
};
