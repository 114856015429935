import { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// Importing the /base version requires the polyfill to be loaded onto the page, as well!!!
import { onLCP, onFID, onCLS, onINP } from 'web-vitals';
import { getClientPageIsLoaded } from 'redux/modules/page/selectors/get-client-page-is-loaded';
import { sendToAnalytics } from './sendToAnalytics';

const WebVitalsReporter = memo(({ clientPageLoaded }) => {
  const [initialReportsSent, setInitialReportsSent] = useState(false);
  const [lastCLSEvent, setLastCLSEvent] = useState(null);

  useEffect(() => {
    onLCP(sendToAnalytics);
    onFID(sendToAnalytics);
    onINP(sendToAnalytics, { reportAllChanges: true });
    onCLS(
      event => {
        setLastCLSEvent(event);
      },
      { reportAllChanges: true },
    );
  }, []);

  useEffect(() => {
    if (clientPageLoaded && lastCLSEvent && !initialReportsSent) {
      sendToAnalytics(lastCLSEvent);
      setInitialReportsSent(true);
    }
  }, [clientPageLoaded, lastCLSEvent, initialReportsSent]);

  return null;
});

WebVitalsReporter.propTypes = {
  clientPageLoaded: PropTypes.bool.isRequired,
};

const ConnectedWebVitalsReporter = connect(state => ({
  clientPageLoaded: getClientPageIsLoaded(state),
}))(WebVitalsReporter);

export default ConnectedWebVitalsReporter;
