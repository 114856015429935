import root from 'window-or-global';
import { dataLayer } from 'analytics/data-layer';
import { getSelector } from 'utils/get-selector';

function getLargestLayoutShiftEntry(entries) {
  return entries.reduce((a, b) => (a && a.value > b.value ? a : b));
}

function getLargestLayoutShiftSource(sources) {
  return sources.reduce((a, b) =>
    a.node &&
    a.previousRect.width * a.previousRect.height > b.previousRect.width * b.previousRect.height
      ? a
      : b,
  );
}

function wasFIDBeforeDCL(fidEntry) {
  const navEntry =
    root.performance.getEntriesByType && root.performance.getEntriesByType('navigation')[0];
  return navEntry && fidEntry.startTime < navEntry.domContentLoadedEventStart;
}

function getDebugInfo(name, entries = []) {
  // In some cases there won't be any entries (e.g. if CLS is 0,
  // or for LCP after a bfcache restore), so we have to check first.
  if (entries.length) {
    if (name === 'LCP') {
      const lastEntry = entries[entries.length - 1];
      return {
        debug_target: getSelector(lastEntry.element),
        event_time: lastEntry.startTime,
      };
    }
    if (name === 'FID') {
      const firstEntry = entries[0];
      return {
        debug_target: getSelector(firstEntry.target),
        debug_event: firstEntry.name,
        debug_timing: wasFIDBeforeDCL(firstEntry) ? 'pre_dcl' : 'post_dcl',
        event_time: firstEntry.startTime,
      };
    }
    if (name === 'INP') {
      const firstEntry = entries[0];
      return {
        debug_target: getSelector(firstEntry.target),
        debug_event: firstEntry.name,
        event_time: firstEntry.startTime,
      };
    }
    if (name === 'CLS') {
      const largestEntry = getLargestLayoutShiftEntry(entries);
      if (largestEntry && largestEntry.sources && largestEntry.sources.length) {
        const largestSource = getLargestLayoutShiftSource(largestEntry.sources);
        return {
          debug_target: getSelector(largestSource.node),
          event_time: largestEntry.startTime,
        };
      }
    }
  }
  // Return default/empty params in case there are no entries.
  return {
    debug_target: '(not set)',
  };
}

export function sendToAnalytics({ name, value, entries }) {
  const report = {
    url: root.location.href,
    name,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    ...getDebugInfo(name, entries),
  };
  dataLayer.push({
    event: 'web_vitals',
    web_vitals: {
      ...report,
    },
  });
}
